import React, { useState, useEffect } from 'react';
import cn from 'src/utilities/bem-cn';

// styles
import './styles.scss';

// icons
import warningIcon from 'public/images/warning.svg';

const className = 'toaster';
const el = (name) => cn(className, name);

const Toaster = ({ message, visible, onClose }) => {
	useEffect(() => {
		if (visible) {
			const timer = setTimeout(() => {
				if (onClose) onClose();
			}, 4000);

			return () => clearTimeout(timer);
		}
	}, [visible, onClose]);

	return (
		<div className={`${el('toaster-container')} ${visible ? 'show' : ''}`}>
			<div className={el('toaster')}>
				<div className={el('toaster-icon')}>
					<img src={warningIcon} alt="w" />
				</div>
				<p>{message}</p>
			</div>
		</div>
	);
};

export default Toaster;
