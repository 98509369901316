// packages
import React from 'react';
import PropTypes from 'prop-types';

// styles
import './styles.scss';

const AnimatedBlob = ({ width = 226, height = 203 }) => (
	<svg viewBox="0 0 226 203" fill="none" className="animated-blob" {...{ width, height }}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M37.0906 80.6279C34.5782 63.5471 40.0976 45.5506 53.527 32.6812C69.093 17.7642 91.1291 13.7193 110.204 20.3564C131.682 8.36207 159.363 11.7961 177.21 30.3352C187.191 40.7035 192.317 53.948 192.643 67.2695C196.034 69.4981 199.231 72.1355 202.162 75.1804C223.363 97.2036 222.657 132.203 200.586 153.354C192.161 161.428 181.841 166.317 171.121 168.051C168.28 174.907 164.002 181.301 158.296 186.769C136.225 207.92 101.146 207.213 79.9447 185.19C75.4538 180.524 71.9459 175.277 69.4157 169.702C58.631 167.589 48.3673 162.262 40.1755 153.752C20.5854 133.403 19.6993 101.973 37.0906 80.6279Z"
			fill="#E3F4EC"
		/>
	</svg>
);

AnimatedBlob.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};

export default AnimatedBlob;
