import { getMainState } from '../../selectors';

export { getMainState };

// TODO: Consider getting rid of 'getStep' later if possible
export const getStep = (state) => getMainState(state).step;
export const getState = (state) => getMainState(state).state;
export const getStudy = (state) => getMainState(state).study;
export const getResponseId = (state) => getMainState(state).responseId;
export const getResponseRID = (state) => getMainState(state).RID;
export const getResults = (state) => getMainState(state).results;
export const getQualifiers = (state) => getMainState(state).qualifiers;
export const getAnswers = (state) => getMainState(state).answers;
export const getRemovedAnswers = (state) => getMainState(state).removedAnswers;
export const getTextAIProductId = (state) => getMainState(state).textAIProductIds;
export const getSentimentTags = (state) => getMainState(state).sentimentTags;
export const getSentimentText = (state) => getMainState(state).sentimentText;
export const getCurrentSectionIndex = (state) => getMainState(state).currentSectionIndex;
export const getSections = (state) => getMainState(state).sections;
export const getCurrentSection = (state) => getMainState(state).sections[getMainState(state).currentSectionIndex];
export const getQuestionIndex = (state) => getMainState(state).questionIndex;
export const getFocusStartTime = (state) => getMainState(state).focusStartTime;
export const getFocusEndTime = (state) => getMainState(state).focusEndTime;
export const getInputTypingTime = (state) => getMainState(state).inputTypingTime;
export const getPreviewUiid = (state) => getMainState(state).study?.previewUuid;
export const getIsDistributedSplitProductsLoading = (state) => getMainState(state).distributedSplitProductsLoading;
export const getIsAudioOnly = (state) => getMainState(state).audioOnly;
export const getProductIndex = (state) => getMainState(state).productIndex;
