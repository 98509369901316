// packages
import { useState, useEffect, useCallback } from 'react';
import { detectBrowser } from 'src/utilities/misc';

const useMediaPermissions = () => {
	// state
	const [permissions, setPermissions] = useState({
		isAudioPermissionGranted: false,
		isVideoPermissionGranted: false,
	});
	const [error, setError] = useState(null);

	// functions
	const checkPermission = useCallback(async (permission) => {
		try {
			const browser = detectBrowser();
			if (browser === 'Firefox') {
				const devices = await navigator.mediaDevices.enumerateDevices();
				const device = devices?.find((device) =>
					(device.kind === permission) === 'camera' ? 'videoinput' : 'audioinput',
				);

				if (permission === 'camera') {
					const videoStream = await navigator.mediaDevices.getUserMedia({
						// ? Specifying the deviceId is necessary to gain camera permissions in Firefox.
						video: { deviceId: device?.deviceId },
					});
					videoStream?.getTracks?.().forEach((track) => track?.stop?.());
					return !!videoStream;
				}
				if (permission === 'microphone') {
					const audioStream = await navigator.mediaDevices.getUserMedia({
						audio: true,
					});
					audioStream?.getTracks?.().forEach((track) => track?.stop?.());
					return !!audioStream;
				}
				const stream = await navigator.mediaDevices.getUserMedia({
					audio: true,
					// ? Specifying the deviceId is necessary to gain camera permissions in Firefox.
					video: { deviceId: device?.deviceId },
				});
				stream?.getTracks?.().forEach((track) => track?.stop?.());
				return !!stream;
			}
			const result = await navigator.permissions.query({ name: permission });
			return result.state === 'granted';
		} catch (err) {
			setError(err);
			return false;
		}
	}, []);

	const updatePermissions = useCallback(async () => {
		const [isAudioPermissionGranted, isVideoPermissionGranted] = await Promise.all(
			['microphone', 'camera'].map((permission) => checkPermission(permission)),
		);

		const updatedPermissions = {
			isAudioPermissionGranted,
			isVideoPermissionGranted,
		};

		setPermissions(updatedPermissions);

		return updatedPermissions;
	}, [checkPermission]);

	const requestPermissions = useCallback(
		async (
			requestedPermissions = {
				audio: true,
				video: true,
			},
		) => {
			try {
				const stream = await navigator.mediaDevices.getUserMedia(requestedPermissions);

				const updatedPermissions = {
					isAudioPermissionGranted: !!stream.getAudioTracks()?.length,
					isVideoPermissionGranted: !!stream.getVideoTracks()?.length,
				};

				// Stop all tracks to release the media devices once permission is granted
				stream.getTracks().forEach((track) => track.stop());

				setPermissions(updatedPermissions);

				return updatedPermissions;
			} catch (err) {
				setPermissions((prevPermissions) => ({
					isAudioPermissionGranted:
						err.name !== 'NotAllowedError' && prevPermissions.isAudioPermissionGranted,
					isVideoPermissionGranted:
						err.name !== 'NotAllowedError' && prevPermissions.isVideoPermissionGranted,
				}));
				setError(err);
				return {
					isAudioPermissionGranted: false,
					isVideoPermissionGranted: false,
				};
			}
		},
		[],
	);

	// life cycle
	useEffect(() => {
		updatePermissions();
	}, [updatePermissions]);

	return {
		permissions,
		error,
		refreshMediaPermissions: updatePermissions,
		requestMediaPermissions: requestPermissions,
		checkPermission,
	};
};

export default useMediaPermissions;
