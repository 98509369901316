import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'src/utilities/bem-cn';
import useIsMobile from 'src/utilities/hooks/useIsMobile';
import { detectBrowser } from 'src/utilities/misc';

import xIcon from 'public/images/x_icon.svg';
import ChromeSettingIcon from 'public/images/setting-icon.svg';
import FirefoxSettingIcon from 'public/images/firefox-setting-icon.svg';
import EdgeSettingIcon from 'public/images/edge-setting-icon.svg';

import iosMicCamera from 'public/images/ios-mic-camera.png';
import iosMic from 'public/images/ios-mic.svg';
import iosCamera from 'public/images/ios-camera.svg';

import chromeMicCamera from 'public/images/android-mic-camera.svg';
import chromeCamera from 'public/images/android-camera.svg';
import chromeMic from 'public/images/android-mic.svg';

import edgeMicCamera from 'public/images/edge-mic-camera.svg';
import edgeCamera from 'public/images/edge-camera.svg';
import edgeMic from 'public/images/edge-mic.svg';

import firefoxMicCamera from 'public/images/firefox-mic-camera.svg';
import firefoxCamera from 'public/images/firefox-camera.svg';
import firefoxMic from 'public/images/firefox-mic.svg';

import ChromeBar from 'public/images/upsiide-url-bar.svg';
import EdgeBar from 'public/images/edge-bar.svg';
import FirefoxBar from 'public/images/firefox-bar.svg';

import EdgePadlock from 'public/images/edge-padlock.png';
import FirefoxPadlock from 'public/images/firefox-padlock.png';
import ChromePadlock from 'public/images/chrome-padlock.svg';

import SystemSettingImage from 'public/images/system-setting-image.svg';
import ChromeUrlBar from 'public/images/chrome-url-bar.svg';
import GenericFullSettingsImage from 'public/images/full-setting-image.svg';
import ChromeMicSetting from 'public/images/chrome-mic-setting.svg';
import ChromeCameraSetting from 'public/images/chrome-camera-setting.svg';
import SafariUrlBar from 'public/images/safari-url-bar.svg';
import SafariStep2 from 'public/images/safari-step-2.svg';
import SafariStep2Mic from 'public/images/safari-step-2-mic.svg';
import SafariStep2Camera from 'public/images/safari-step-2-camera.svg';
import SafariStep3 from 'public/images/safari-step-3.svg';
import SafariStep3Mic from 'public/images/safari-step-3-mic.svg';
import SafariStep3Camera from 'public/images/safari-step-3-camera.svg';
import SafariReloadIcon from 'public/images/safari-reload-icon.svg';
import EdgeUrlBar from 'public/images/edge-url-bar.svg';
import EdgeStep2 from 'public/images/edge-step-2.svg';
import EdgeStep2Mic from 'public/images/edge-step-2-mic.svg';
import EdgeStep2Camera from 'public/images/edge-step-2-camera.svg';
import FirefoxUrlBar from 'public/images/firefox-url-bar.svg';
import FirefoxStep2 from 'public/images/firefox-step-2.svg';
import FirefoxStep2Mic from 'public/images/firefox-step-2-mic.svg';
import FirefoxStep2Camera from 'public/images/firefox-step-2-camera.svg';
import FirefoxStep3 from 'public/images/firefox-step-3.svg';
import FirefoxStep3Mic from 'public/images/firefox-step-3-mic.svg';
import FirefoxStep3Camera from 'public/images/firefox-step-3-camera.svg';

import './styles.scss';

const className = 'modal-permission-settings';

const el = (name) => cn(className, name);

const ModalPermissionSettings = ({
	retry,
	isSystem = false,
	micPermission,
	cameraPermission,
	onClose,
	customStyle,
	userPlatform,
}) => {
	const browser = detectBrowser();
	const isMobile = useIsMobile();
	const isIos = userPlatform === 'ios';

	const urlBar = useMemo(() => {
		if (browser === 'Safari') {
			return SafariUrlBar;
		}
		if (browser === 'Edge') {
			return EdgeUrlBar;
		}
		if (browser === 'Firefox') {
			return FirefoxUrlBar;
		}
		return ChromeUrlBar;
	}, []);

	const SettingIcon = useMemo(() => {
		if (browser === 'Edge') {
			return EdgeSettingIcon;
		}
		if (browser === 'Firefox') {
			return FirefoxSettingIcon;
		}
		return ChromeSettingIcon;
	}, []);

	const SettingAndroidIcon = useMemo(() => {
		if (browser === 'Edge') {
			return EdgePadlock;
		}
		if (browser === 'Firefox') {
			return FirefoxPadlock;
		}
		return ChromePadlock;
	}, []);

	const UpsiideBar = useMemo(() => {
		if (browser === 'Edge') {
			return EdgeBar;
		}
		if (browser === 'Firefox') {
			return FirefoxBar;
		}
		return ChromeBar;
	}, []);

	const androidMicCamera = useMemo(() => {
		if (browser === 'Edge') {
			return edgeMicCamera;
		}
		if (browser === 'Firefox') {
			return firefoxMicCamera;
		}
		return chromeMicCamera;
	}, []);

	const androidCamera = useMemo(() => {
		if (browser === 'Edge') {
			return edgeCamera;
		}
		if (browser === 'Firefox') {
			return firefoxCamera;
		}
		return chromeCamera;
	}, []);

	const androidMic = useMemo(() => {
		if (browser === 'Edge') {
			return edgeMic;
		}
		if (browser === 'Firefox') {
			return firefoxMic;
		}
		return chromeMic;
	}, []);

	const PadlockStyle = useMemo(() => {
		if (browser === 'Edge') {
			return {
				position: 'relative',
				top: 6,
				marginRight: 4,
				marginLeft: 4,
			};
		}
		if (browser === 'Firefox') {
			return {
				position: 'relative',
				top: 6,
				marginRight: 4,
				marginLeft: 4,
			};
		}
		return {
			position: 'relative',
			top: 7,
			marginLeft: 4,
		};
	}, []);

	const renderTitle = () => 'How to Enable Site Permissions';

	const renderStep2 = () => {
		switch (browser) {
			case 'Safari':
				return (
					<>
						<span className={el('modal-text-step2')}>2. Open “Settings for Upsiide.com”.</span>
						<img
							src={(() => {
								if (micPermission && cameraPermission) return SafariStep2;
								if (micPermission) return SafariStep2Mic;
								if (cameraPermission) return SafariStep2Camera;
								return SafariStep2;
							})()}
							alt="url bar"
							className={el('url-bar')}
						/>
					</>
				);
			case 'Edge':
				return (
					<>
						<span className={el('modal-text-step2')}>2. Open “Settings for Upsiide.com”.</span>
						<img
							src={(() => {
								if (micPermission && cameraPermission) return EdgeStep2;
								if (micPermission) return EdgeStep2Mic;
								if (cameraPermission) return EdgeStep2Camera;
								return EdgeStep2;
							})()}
							alt="url bar"
							className={el('url-bar')}
						/>
					</>
				);
			case 'Firefox':
				return (
					<>
						<span className={el('modal-text-step2')}>2. Deselect “Blocked Temporarily”.</span>
						<img
							src={(() => {
								if (micPermission && cameraPermission) return FirefoxStep2;
								if (micPermission) return FirefoxStep2Mic;
								if (cameraPermission) return FirefoxStep2Camera;
								return FirefoxStep2;
							})()}
							alt="url bar"
							className={el('url-bar')}
						/>
					</>
				);
			default:
				return (
					<>
						<span className={el('modal-text-step2')}>2. Use the toggle in settings to enable access. </span>
						<img
							src={(() => {
								if (micPermission && cameraPermission) return GenericFullSettingsImage;
								if (micPermission) return ChromeMicSetting;
								if (cameraPermission) return ChromeCameraSetting;
								return GenericFullSettingsImage;
							})()}
							alt="settings"
							className={el('setting-banner')}
						/>
					</>
				);
		}
	};

	const renderStep3 = () => {
		switch (browser) {
			case 'Safari':
				return (
					<>
						<span className={el('modal-text-step2')}>
							3. Allow{' '}
							{(() => {
								if (micPermission && cameraPermission) return 'microphone and camera';
								if (micPermission) return 'microphone';
								if (cameraPermission) return 'camera';
								return '';
							})()}{' '}
							permissions.
						</span>
						<img
							src={(() => {
								if (micPermission && cameraPermission) return SafariStep3;
								if (micPermission) return SafariStep3Mic;
								if (cameraPermission) return SafariStep3Camera;
								return SafariStep3;
							})()}
							alt="url bar"
							className={el('url-bar')}
						/>
					</>
				);
			case 'Firefox':
				return (
					<>
						<span className={el('modal-text-step2')}>3. Click Allow to enable access.</span>
						<img
							src={(() => {
								if (micPermission && cameraPermission) return FirefoxStep3;
								if (micPermission) return FirefoxStep3Mic;
								if (cameraPermission) return FirefoxStep3Camera;
								return FirefoxStep3;
							})()}
							alt="url bar"
							className={el('url-bar')}
						/>
					</>
				);
			default:
				return null;
		}
	};

	const renderStep4 = () => {
		switch (browser) {
			case 'Safari':
				return (
					<span className={el('modal-text-step2')}>
						4. Click the icon
						<img className={el('img-setting')} alt="setting icon" src={SafariReloadIcon} /> on the top
						central side to reload the page.
					</span>
				);
			case 'Firefox':
				return (
					<span className={el('modal-text-step2')}>
						4. Click the icon
						<img className={el('img-setting')} alt="setting icon" src={SafariReloadIcon} /> on the top
						central side to reload the page.
					</span>
				);
			default:
				return null;
		}
	};

	const renderContent = () => {
		if (isSystem) {
			return (
				<div className={el('modal-content')}>
					<div className={el('modal-text')}>
						<div className={el('modal-text-step1')}>
							<span>1. In System Settings, open Privacy & Security</span>
						</div>
						<span className={el('modal-text-step2')}>2. Allow access to your camera</span>
						<span className={el('modal-text-step2')}>3. Allow access to your microphone</span>
						<img src={SystemSettingImage} alt="settings" className={el('setting-banner')} />
					</div>
				</div>
			);
		}
		return (
			<div className={el('modal-content')}>
				<div className={el('modal-text')}>
					<div className={el('modal-text-step1')}>
						{SettingIcon ? (
							<>
								1. Click the icon{' '}
								<img className={el('img-setting')} alt="setting icon" src={SettingIcon} />{' '}
								<span>on the left side of the URL bar </span>
								<span>to open site permission settings.</span>
							</>
						) : (
							<span>Click the “Safari” menu on the top left side.</span>
						)}
					</div>
					{urlBar ? <img src={urlBar} alt="url bar" className={el('url-bar')} /> : null}
					{renderStep2()}
					{renderStep3()}
					{renderStep4()}
				</div>
			</div>
		);
	};

	const renderFooter = () => {
		if (isMobile) {
			return (
				<div className={el('modal-footer')}>
					<div
						aria-hidden
						onClick={() => {
							retry();
							onClose();
						}}
						className={el('modal-button-primary')}
					>
						<span className={el('modal-allow')}>Ok</span>
					</div>

					{/* <div aria-hidden onClick={onClose} className={el('modal-button')}>
						<span className={el('modal-reject')}>Close</span>
					</div> */}
				</div>
			);
		}

		return (
			<div className={el('modal-footer')}>
				{/* <div aria-hidden onClick={onClose} className={el('modal-button')}>
					<span className={el('modal-reject')}>Close</span>
				</div> */}

				<div aria-hidden onClick={retry} className={el('modal-button-primary')}>
					<span className={el('modal-allow')}>Got It</span>
				</div>
			</div>
		);
	};

	const getSystemAsset = () => {
		if (isIos) {
			if (micPermission && cameraPermission) return iosMicCamera; // if true then is denied
			if (!micPermission && cameraPermission) return iosCamera;
			if (micPermission && !cameraPermission) return iosMic;
		}
		if (micPermission && cameraPermission) return androidMicCamera; // if true then is denied
		if (!micPermission && cameraPermission) return androidCamera;
		if (micPermission && !cameraPermission) return androidMic;

		return androidMicCamera;
	};
	const getSystemText = () => {
		if (isIos) {
			if (micPermission && cameraPermission) return 'Toggle on Microphone and Camera.';
			if (!micPermission && cameraPermission) return 'Toggle on Camera.';
			if (micPermission && !cameraPermission) return 'Toggle on Microphone.';
		}
		if (browser === 'Edge') {
			return (
				<>
					<li>
						<span className={el('modal-text-step2')}>Select "website settings".</span>
					</li>
					<li className={el('modal-text-item')}>
						<span className={el('modal-text-step2')}>Then, push the button "delete and reestablish".</span>
					</li>
				</>
			);
		}
		if (browser === 'Firefox') {
			return (
				<li>
					<span className={el('modal-text-step2')}>
						Then, you have to select the "blocked" button near the right side of the camera and microphone
						icon.
					</span>
				</li>
			);
		}
		return (
			<>
				<li>
					<span className={el('modal-text-step2')}>Select "Permissions" settings.</span>
				</li>
				<li className={el('modal-text-item')}>
					<span className={el('modal-text-step2')}>Use the toggle in settings to enable access.</span>
				</li>
			</>
		);
	};
	const renderAndroidExtraStep = () => {
		if (browser === 'Edge') {
			return (
				<li className={el('modal-text-item')}>
					<span className={el('modal-text-step2')}>
						Again, appears a notification and you should tap "delete and reestablish" again.
					</span>
				</li>
			);
		}
		if (browser === 'Firefox') {
			return (
				<li className={el('modal-text-item')}>
					<span className={el('modal-text-step2')}>
						It will change to "allowed" automatically when you push it.
					</span>
				</li>
			);
		}
		return null;
	};

	const renderIos = () => (
		<div className={el('modal-content')}>
			<div className={el('modal-text')}>
				<span className={el('modal-text-step2')} style={{ marginTop: 12, marginBottom: 24 }}>
					The survey needs to restart, and you’ll be asked to enable microphone and camera access.
				</span>
				<span className={el('modal-text-step2')} style={{ marginTop: 0, marginBottom: 24, fontWeight: '600' }}>
					How to enable access in System Settings:
				</span>
				<ol className={el('modal-text-number')}>
					<div className={el('modal-text-step1')}>
						<li>
							<span>In System Settings, open your browser app settings.</span>
						</li>
					</div>
					<li style={{ marginTop: 8 }}>
						<span className={el('modal-text-step2')}>{getSystemText()}</span>
					</li>
				</ol>
				<img
					src={getSystemAsset()}
					alt="settings"
					className={el('setting-banner')}
					style={{ width: 'unset' }}
				/>
			</div>
		</div>
	);
	const renderAndroid = () => (
		<div className={el('modal-content')}>
			<div className={el('modal-text')}>
				<ol className={el('modal-text-number')}>
					<div className={el('modal-text-step1')}>
						<li>
							<span>
								Tap the icon{' '}
								<img
									className={el('img-setting')}
									alt="setting icon"
									src={SettingAndroidIcon}
									style={PadlockStyle}
								/>{' '}
								<span>on the left side of the URL bar.</span>
							</span>
						</li>

						<span>
							<img className={el('img-bar')} alt="setting icon" src={UpsiideBar} />
						</span>
					</div>
					{getSystemText()}

					<img src={getSystemAsset()} alt="settings" style={{ marginTop: 15 }} />
					{renderAndroidExtraStep()}
				</ol>
			</div>
		</div>
	);

	if (isMobile)
		return (
			<>
				<div className={el('modal-overlay')} />

				<div className={el('modal-permission')} style={customStyle}>
					<div className={el('modal-container')}>
						<div className={el('modal-header')}>
							<span className={el('modal-title-system')}>
								{isIos ? 'Survey Restart Required' : 'How to Enable Permissions'}
							</span>
							<img src={xIcon} alt="close" onClick={onClose} aria-hidden />
						</div>

						<div className={el('modal-content')}>{isIos ? renderIos() : renderAndroid()}</div>
						<div className={el('modal-footer')} style={{ marginTop: 32 }}>
							<div
								aria-hidden
								onClick={() => {
									if (isIos) retry();
									else onClose();
								}}
								className={el('modal-button-primary')}
							>
								<span className={el('modal-allow')}>
									{isIos ? 'I got it, restart survey' : 'I got it'}
								</span>
							</div>
						</div>
					</div>
				</div>
			</>
		);

	return (
		<>
			<div className={el('modal-overlay')} />

			<div className={el('modal-permission')} style={customStyle}>
				<div className={el('modal-container')}>
					<div className={el('modal-header')}>
						<span className={el('modal-title')}>{renderTitle()}</span>
						<img src={xIcon} alt="close" onClick={onClose} aria-hidden />
					</div>
					{renderContent()}
					{renderFooter()}
				</div>
			</div>
		</>
	);
};

ModalPermissionSettings.propTypes = {
	retry: PropTypes.func,
	isSystem: PropTypes.bool,
	micPermission: PropTypes.bool,
	cameraPermission: PropTypes.bool,
	onClose: PropTypes.func,
	customStyle: PropTypes.object,
	userPlatform: PropTypes.string,
};

export default ModalPermissionSettings;
