// packages
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
// utilities
import cn from 'src/utilities/bem-cn';
// actions
import * as actions from 'src/domains/main/actions';
// hooks
import useMediaPermissions from 'src/utilities/hooks/useMediaPermissions';
// selectors
import * as selectors from '../../../../../selectors';
// components
import ModalAreYouSure from './ModalAreYouSure';
import WelcomeChecker from './WelcomeChecker';
import PermissionCheck from './PermissionCheck';
import PreviewChecker from './PreviewChecker';
// styles
import './styles.scss';

const className = 'video-checker';
const el = (name) => cn(className, name);

const IDLE_TIME = 5; // minutes
const VISIBILITY_TIME = 2; // minutes

const VideoPermissionChecker = ({ onProceed, disqualifyOnReject, onSkip, question, currentSection }) => {
	// state
	const [areYouSure, setAreYouSure] = useState(false);
	const [showPreview, setShowPreview] = useState('welcome');

	const { study } = useSelector((state) => state.main);
	const sections = useSelector((state) => selectors.getSections(state));
	const { answers } = useSelector((state) => state.main);

	const dispatch = useDispatch();
	const setSectionAnswers = (a) => dispatch(actions.setAnswers(a));
	const { refreshMediaPermissions, checkPermission } = useMediaPermissions();

	const terminate = (reason = `video-question-device-permission`) =>
		dispatch(actions.setDisqualified([], study?.audienceUuid, reason));

	const qualityTerminate = useCallback(() => terminate(`video-permission-drop-off`), [terminate]);

	const idleTerminate = useCallback(() => terminate(`video-permission-idle`), [terminate]);

	const hiddenTerminate = useCallback(() => terminate(`video-permission-hidden`), [terminate]);

	const allowAudioOnly = useMemo(() => {
		const videoQuestions = [];
		for (const section of sections) {
			videoQuestions.push(
				...(section?.questions?.filter(({ style }) => style === 'guided-video-question') || []),
			);
		}
		return !videoQuestions.some(
			({ settings }) => settings.find(({ label }) => label === 'allow_audio_only')?.value === 'false',
		);
	}, [sections]);

	const visibilityTimerId = useRef(null);
	const idleTimerId = useRef(null);

	// =================================================================================================

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.hidden) {
				const id = setTimeout(() => {
					hiddenTerminate();
				}, VISIBILITY_TIME * 60 * 1000);
				visibilityTimerId.current = id;
			} else {
				clearTimeout(visibilityTimerId.current);
				visibilityTimerId.current = null;
			}
		};

		document.addEventListener('visibilitychange', handleVisibilityChange);

		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
			clearTimeout(visibilityTimerId.current);
		};
	}, [hiddenTerminate]);

	useEffect(() => {
		const resetIdleTimer = () => {
			clearTimeout(idleTimerId.current);
			const id = setTimeout(() => {
				idleTerminate();
			}, IDLE_TIME * 60 * 1000);
			idleTimerId.current = id;
		};

		const events = ['mousemove', 'keydown', 'scroll', 'click'];

		events.forEach((event) => {
			window.addEventListener(event, resetIdleTimer);
		});

		resetIdleTimer(); // init timer

		return () => {
			events.forEach((event) => {
				window.removeEventListener(event, resetIdleTimer);
			});
			clearTimeout(idleTimerId.current);
		};
	}, [idleTerminate]);

	// =================================================================================================

	useEffect(() => {
		window.addEventListener('beforeunload', qualityTerminate);

		return () => {
			window.removeEventListener('beforeunload', qualityTerminate);
		};
	}, [qualityTerminate]);

	useEffect(() => {
		sessionStorage.removeItem('moderation-disqualify');
		const innerScrollContainer = document.querySelector(`.scroll-container__inner`);
		if (innerScrollContainer) {
			innerScrollContainer.classList.add('scroll-container__inner--rtl');
		}
		return () => {
			innerScrollContainer.classList.remove('scroll-container__inner--rtl');
		};
	}, []);

	useEffect(() => {
		if (showPreview === 'preview') refreshMediaPermissions();
	}, [showPreview]);

	// functions

	const handelSkip = () => {
		setSectionAnswers({
			...answers,
			[question.id]: {
				...answers[question.id],
				value: 'SKIP', // * optionId
				type: 'guided-video-question', // * Type of question
				sectionId: currentSection.id, // * ID of the current section
			},
		});

		if (navigator?.allMediaStreams?.length) {
			navigator.allMediaStreams.forEach((stream) => {
				stream.getTracks().forEach((track) => {
					track.stop();
				});
			});
		}

		onSkip();
	};

	const handleContinue = async () => {
		try {
			window.sessionStorage.removeItem('skipVideoQuestion');

			const camera = await checkPermission('camera');
			const mic = await checkPermission('microphone');

			if (camera && mic) {
				return setShowPreview('preview');
			}
			setShowPreview('permission');
		} catch (error) {
			console.log('Error in handleContinue', error);
		}
	};

	const renderComponent = () => {
		if (showPreview === 'welcome') {
			return (
				<WelcomeChecker
					handleContinue={handleContinue}
					setAreYouSure={setAreYouSure}
					disqualifyOnReject={disqualifyOnReject}
					handelSkip={handelSkip}
				/>
			);
		}
		if (showPreview === 'permission' || showPreview === 'denied') {
			return (
				<PermissionCheck
					setAreYouSure={setAreYouSure}
					setShowPreview={setShowPreview}
					qualityTerminate={qualityTerminate}
				/>
			);
		}

		return (
			<PreviewChecker
				setAreYouSure={setAreYouSure}
				onProceed={onProceed}
				disqualifyOnReject={disqualifyOnReject}
				onSkip={onSkip}
				question={question}
				currentSection={currentSection}
				setShowPreview={setShowPreview}
			/>
		);
	};

	return (
		<div className={className}>
			{renderComponent()}
			{areYouSure && (
				<ModalAreYouSure
					allowAudioOnly={allowAudioOnly}
					onClose={() => setAreYouSure(false)}
					onDeny={() => setAreYouSure(false)}
					onSuccess={() => {
						if (disqualifyOnReject) {
							terminate();
						} else {
							handelSkip();
						}
					}}
					flowAudioOnly={false}
					flowReject={false}
					onAudioOnly={false}
				/>
			)}
		</div>
	);
};

VideoPermissionChecker.propTypes = {
	onProceed: PropTypes.func.isRequired,
	onSkip: PropTypes.func.isRequired,
	disqualifyOnReject: PropTypes.bool.isRequired,
	question: PropTypes.object.isRequired,
	currentSection: PropTypes.object.isRequired,
};

export default VideoPermissionChecker;
