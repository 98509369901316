// packages
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';

import { PropTypes } from 'prop-types';
// assets
import BackgroundNoImage from 'public/images/background-no-image.png';
// utilities
import cn from 'src/utilities/bem-cn';

// hooks
import useMediaPermissions from 'src/utilities/hooks/useMediaPermissions';
import useElementRect from 'src/utilities/hooks/useElementRect';
// styles
import './styles.scss';

const className = 'preview-checker';

const el = (name) => cn(className, name);

const FREQUENCY_BIN_COUNT = 2 ** 5;
const dataArray = new Uint8Array(FREQUENCY_BIN_COUNT);

const Preview = ({ renderHasSelectedAudioOnly, renderNoPermission, renderAudioBars, getCanvasFrame = () => {} }) => {
	// state
	const [isVideoMirrored, setIsVideoMirrored] = useState(false);
	const [isVoiceDetected, setIsVoiceDetected] = useState(false);

	const [cameraPermission, setCameraPermission] = useState(false);
	const [microphonePermission, setMicrophonePermission] = useState(false);

	const { refreshMediaPermissions } = useMediaPermissions();

	const [squareWrapperRect, squareWrapperRef] = useElementRect();
	const squareWrapperSideSize = useMemo(() => squareWrapperRect.width, [squareWrapperRect.width]);
	const canvasWidth = useMemo(() => squareWrapperSideSize || 600, [squareWrapperSideSize]);
	const canvasHeight = useMemo(() => squareWrapperSideSize || 600, [squareWrapperSideSize]);

	const canvasRef = useRef(null);
	const audioStream = useRef(null);

	const frontCamera = useRef(null);
	const cameraSettings = useRef({
		originX: 0,
		originY: 0,
		destW: 380,
		destH: 380,
		translateX: 0,
		scaleX: 1,
		canvasWidth,
		canvasHeight,
	});
	const deviceListRef = useRef([]);
	const audioContextRef = useRef(null);

	// functions
	const checkAllowPermission = async () => {
		const { isAudioPermissionGranted: microphone, isVideoPermissionGranted: camera } =
			await refreshMediaPermissions();

		if (!camera && !microphone) return getPermission();
		if (!camera && microphone) {
			return getPermission(true, false);
		}
		if (camera && microphone) return getPermission();

		if (camera && !microphone) {
			return getPermission(false, true);
		}
	};

	useEffect(() => {
		// Initialize AudioContext if it's not already created
		if (!audioContextRef.current) {
			audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
		}

		// Clean up the AudioContext when the component unmounts
		return () => {
			if (navigator?.allMediaStreams?.length) {
				navigator.allMediaStreams.forEach((stream) => {
					stream.getTracks().forEach((track) => {
						track.stop();
					});
				});
			}
			if (audioContextRef.current && typeof audioContextRef.current.close === 'function') {
				audioContextRef.current.close();
			}
		};
	}, []);

	const initialCheck = async () => {
		try {
			const videoPermission = await navigator.mediaDevices.getUserMedia({
				video: true,
			});
			saveMediaStream(videoPermission);
			setCameraPermission(videoPermission);
			checkAllowPermission();
		} catch (error) {
			console.log('Camera => ', error);
			setCameraPermission(false);
		}
		try {
			const audioPermission = await navigator.mediaDevices.getUserMedia({ audio: true });
			saveMediaStream(audioPermission);
			setMicrophonePermission(audioPermission);
			checkAllowPermission();
		} catch (error) {
			console.log('Mic => ', error);
			setMicrophonePermission(false);
		}
	};

	useEffect(() => {
		initialCheck();
	}, []);

	const saveMediaStream = (mediaStream) => {
		if (!navigator?.allMediaStreams) {
			navigator.allMediaStreams = [];
		}
		navigator.allMediaStreams.push(mediaStream);
	};

	const getPermission = async (audio, edgeCase = false) => {
		try {
			const devices = await navigator.mediaDevices.enumerateDevices();
			const cameras = devices.filter((device) => device.kind === 'videoinput');
			if (edgeCase) {
				deviceListRef.current = cameras;
				const audioMedia = await navigator.mediaDevices.getUserMedia({ video: true });
				saveMediaStream(audioMedia);
				audioStream.current = audioMedia;
				return startOnlyCameraPreview(cameras[0]);
			}
			if (cameras.length && !audio) {
				deviceListRef.current = cameras;
				const audioMedia = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
				saveMediaStream(audioMedia);
				audioStream.current = audioMedia;
				startCameraPreview(cameras[0]);
			} else {
				const audioMedia = await navigator.mediaDevices.getUserMedia({ audio: true });
				saveMediaStream(audioMedia);
				audioStream.current = audioMedia;
				startAudioPreview();
			}
			return true;
		} catch (error) {
			console.log(`No permission was given? => `, error);
			return false;
		}
	};

	const getDeviceStream = async (device) => {
		if (!device) return;
		const capabilities = typeof device?.getCapabilities === 'function' ? device.getCapabilities() : null;

		const videoStream = await navigator.mediaDevices.getUserMedia({
			audio: false,
			video: {
				...(device?.deviceId ? { deviceId: device?.deviceId } : {}),
			},
		});
		saveMediaStream(videoStream);
		const videoTrack = videoStream.getVideoTracks()[0];
		const videoSettings = videoTrack.getSettings();

		const isMirrored = capabilities?.facingMode?.includes('user') || false;

		setIsVideoMirrored(isMirrored);

		if (!isMirrored) {
			cameraSettings.current.translateX = canvasWidth;
			cameraSettings.current.scaleX = -1;
		} else {
			cameraSettings.current.translateX = 0;
			cameraSettings.current.scaleX = 1;
		}

		const isHorizontal = videoSettings.width > videoSettings.height;

		const aspectRatio = isHorizontal
			? videoSettings.height / videoSettings.width
			: videoSettings.width / videoSettings.height;

		const newWidth = isHorizontal ? canvasWidth / aspectRatio : canvasWidth;
		const newHeight = isHorizontal ? canvasHeight : canvasHeight / aspectRatio;

		const x = canvasWidth / 2 - newWidth / 2;
		const y = canvasHeight / 2 - newHeight / 2;

		cameraSettings.current = {
			...cameraSettings.current,
			originX: x,
			originY: y,
			destW: newWidth,
			destH: newHeight,
		};

		return videoStream;
	};

	const startOnlyCameraPreview = async (device) => {
		try {
			const initialStream = await getDeviceStream(device);
			if (!initialStream) return;

			await refreshMediaPermissions();

			frontCamera.current.srcObject = initialStream;

			const poll = () => {
				try {
					if (canvasRef.current) {
						canvasRef.current.width = cameraSettings?.current?.canvasWidth;
						canvasRef.current.height = cameraSettings?.current?.canvasHeight;
					}
					const ctx = canvasRef.current.getContext('2d', { willReadFrequently: true });
					ctx.translate(cameraSettings?.current?.translateX, 0);
					ctx.scale(cameraSettings?.current?.scaleX, 1);
					ctx.drawImage(
						frontCamera.current,
						cameraSettings?.current?.originX,
						cameraSettings?.current?.originY,
						cameraSettings?.current?.destW,
						cameraSettings?.current?.destH,
					);
					requestAnimationFrame(poll);
				} catch (error) {
					cancelAnimationFrame(poll);
				}
			};

			poll();
			setTimeout(() => {
				getCanvasFrame(canvasRef?.current?.toDataURL('image/jpeg', 1.0));
			}, 500);
		} catch (error) {
			console.log(error);
		}
	};

	const startCameraPreview = async (device) => {
		try {
			const initialStream = await getDeviceStream(device);
			if (!initialStream) return;

			await refreshMediaPermissions();

			frontCamera.current.srcObject = initialStream;

			const localAudioStream = await navigator.mediaDevices.getUserMedia({
				audio: true,
			});
			saveMediaStream(localAudioStream);
			startAudioTranscription();

			const poll = () => {
				try {
					if (canvasRef.current) {
						canvasRef.current.width = cameraSettings?.current?.canvasWidth;
						canvasRef.current.height = cameraSettings?.current?.canvasHeight;
					}
					const ctx = canvasRef.current.getContext('2d', { willReadFrequently: true });
					ctx.translate(cameraSettings?.current?.translateX, 0);
					ctx.scale(cameraSettings?.current?.scaleX, 1);
					ctx.drawImage(
						frontCamera.current,
						cameraSettings?.current?.originX,
						cameraSettings?.current?.originY,
						cameraSettings?.current?.destW,
						cameraSettings?.current?.destH,
					);
					requestAnimationFrame(poll);
				} catch (error) {
					cancelAnimationFrame(poll);
				}
			};

			poll();

			setTimeout(() => {
				getCanvasFrame(canvasRef?.current?.toDataURL('image/jpeg', 1.0));
			}, 500);
		} catch (error) {
			console.log(error);
		}
	};

	const renderImageOnCanvas = () => {
		const img = new Image(canvasWidth, canvasHeight);
		img.src = BackgroundNoImage;
		img.onload = () => {
			if (canvasRef?.current) {
				canvasRef.current.style.backgroundColor = '#fafafa';
				canvasRef.current.width = canvasWidth;
				canvasRef.current.height = canvasHeight;
				const ctx = canvasRef.current.getContext('2d');
				ctx.drawImage(img, 0, 0, canvasRef.current.width, canvasRef.current.height);
			}
		};
	};

	const startAudioPreview = async () => {
		try {
			const localAudioStream = await navigator.mediaDevices.getUserMedia({
				audio: true,
			});
			refreshMediaPermissions();

			saveMediaStream(localAudioStream);
			renderImageOnCanvas();
			startAudioTranscription();
		} catch (error) {
			console.error(error);
		}
	};

	const startAudioTranscription = async () => {
		try {
			// const context = new (window.AudioContext || window.webkitAudioContext)();
			const context = audioContextRef.current;
			const source = context.createMediaStreamSource(audioStream?.current);
			const analyser = context.createAnalyser();
			source.connect(analyser);

			analyser.fftSize = 8 * FREQUENCY_BIN_COUNT;

			drawBars(analyser);

			let voiceDetectedAt = performance.now();
			let hasEverTalked = false;
			const detectTime = 2000;
			const statusHoldTime = 500;
			const voiceRms = 32;

			const processAudio = () => {
				const data = new Uint8Array(analyser.frequencyBinCount);
				analyser.getByteFrequencyData(data);
				let rms = 0;

				for (let byteFrequency of data) {
					if (byteFrequency > 120) byteFrequency = 120;
					rms += byteFrequency * byteFrequency;
				}

				rms = Math.sqrt(rms / data.length);
				const silenceTime = performance.now() - voiceDetectedAt;
				const shouldStop = silenceTime >= detectTime && hasEverTalked;
				if (rms > voiceRms) {
					hasEverTalked = true;
					voiceDetectedAt = performance.now();
					setIsVoiceDetected(true);
				} else if (shouldStop) {
					setIsVoiceDetected(false);
					// cancelAnimationFrame(processAudio);
					// return;
				} else if (silenceTime >= statusHoldTime) {
					setIsVoiceDetected(false);
				}
				window.requestAnimationFrame(processAudio);
			};

			processAudio();
		} catch (error) {
			console.log(error);
		}
	};

	const drawBars = (analyser) => {
		try {
			let binI = document.getElementById(`bin-${0}`);
			if (!binI) {
				requestAnimationFrame(() => drawBars(analyser));
			} else {
				analyser.getByteFrequencyData(dataArray);
				const drawThreshold = 5;
				const barHeight = 150;
				const minBarHeight = 1;
				for (let i = 0; i < FREQUENCY_BIN_COUNT; i++) {
					binI = document.getElementById(`bin-${i}`);
					const freqPctg =
						(dataArray[i] / 255) * ((1 - minBarHeight / barHeight) * 100) +
						(minBarHeight / barHeight) * 100 -
						20;

					binI.style.height = `${freqPctg >= drawThreshold ? freqPctg : drawThreshold}%`;
				}
				requestAnimationFrame(() => drawBars(analyser));
			}
		} catch (error) {
			console.log('error', error);
			cancelAnimationFrame(() => drawBars(analyser));
		}
	};

	const renderBarBins = () => {
		const indexes = Array(FREQUENCY_BIN_COUNT)
			.fill(0)
			.map((_, i) => i)
			.sort((a, b) => {
				const aOdd = a % 2 === 0;
				const bOdd = b % 2 === 0;
				if (aOdd === bOdd) return aOdd ? a - b : b - a;
				return aOdd ? 1 : -1;
			});
		return indexes.map((index) => <div className={el('frequency-bar')} id={`bin-${index}`} key={`bin-${index}`} />);
	};

	return (
		<div className={el('left-side-container')}>
			<div ref={squareWrapperRef} className={el('square-wrapper')}>
				<div
					className={el('preview-camera')}
					style={{ width: squareWrapperSideSize ?? 'auto', height: squareWrapperSideSize ?? 'auto' }}
				>
					{isVoiceDetected && <div className={el('active-border')} />}

					{renderHasSelectedAudioOnly()}
					{renderNoPermission()}

					<canvas
						className={el('canvas-video')}
						ref={canvasRef}
						// eslint-disable-next-line no-nested-ternary
						style={{ opacity: cameraPermission ? 1 : 0 }}
					/>

					<video
						ref={frontCamera}
						playsInline
						type="video/mp4"
						autoPlay
						className={el('video-context', isVideoMirrored && 'mirrored')}
						muted
						style={{ opacity: cameraPermission ? 1 : 0 }}
					>
						Your browser does not support the video tag.
					</video>

					{renderAudioBars && <div className={el('audio-bars')}>{renderBarBins()}</div>}
				</div>
			</div>
		</div>
	);
};

Preview.propTypes = {
	renderHasSelectedAudioOnly: PropTypes.func,
	renderNoPermission: PropTypes.func,
	renderAudioBars: PropTypes.bool,
	getCanvasFrame: PropTypes.func,
};

export default Preview;
