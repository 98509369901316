// packages
import React, { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';

// assets
import ZoomIcon from 'public/images/zoom-icon.svg';
import xIcon from 'public/images/x_icon.svg';

// utilities
import cn from 'src/utilities/bem-cn';

// hooks
import useIsMobile from 'src/utilities/hooks/useIsMobile';

// components
import Button from 'src/components/new/Button';
import AnimatedBlob from '../AnimatedBlob';
// styles
import './styles.scss';

const className = 'video-interactor-transcript';

const el = (name, mod) => cn(className, name, mod);

const Transcript = forwardRef(
	(
		{
			aiStatus,
			showImage,
			demoImageURL,
			squareWrapperSideSize,
			setPttEngine,
			question,
			isStartRecording,
			handleContinue,
			canSkip,
			onSkip,
			enableSkip,
		},
		textToShowRef,
	) => {
		// state
		const modalRef = useRef(null);
		const imgModalRef = useRef(null);
		const imgRef = useRef(null);
		const pttButtonRef = useRef(null);

		const isMobile = useIsMobile();

		const pttHold = () => {
			pttButtonRef.current.className = `${pttButtonRef.current.className} active`;
			setPttEngine(true);
		};
		const pttRelease = () => {
			pttButtonRef.current.className = pttButtonRef.current.className.replace(' active', '');
			setPttEngine(false);
		};

		// functions
		const onImageClick = () => {
			modalRef.current.style.display = 'flex';

			imgModalRef.current.src = imgRef.current.src;
			imgModalRef.current.alt = imgRef.current.alt;
		};

		// When the user clicks on <span> (x), close the modal
		const onModalClick = () => {
			imgRef.current.className += ' out';
			setTimeout(() => {
				modalRef.current.style.display = 'none';
				imgRef.current.className = 'modal-content';
			}, 400);
		};

		return (
			<div
				{...{ className }}
				style={{
					width: squareWrapperSideSize ?? 'auto',
					height: isMobile ? 'auto' : squareWrapperSideSize ?? 'auto',
				}}
			>
				{!isStartRecording && (
					<div className={el('welcome-buttons-wrap')}>
						<span className={el('welcome-text')}>Click “Start” to begin the video question.</span>

						<div aria-hidden onClick={handleContinue} className={el('button-next disabled')}>
							<span className={el('next-text')}>Start</span>
						</div>

						{enableSkip && (
							<div
								aria-hidden
								onClick={() => onSkip('SKIP', question?.style)}
								className={el('skip-button')}
							>
								<span className={el('skip-reject')}>Skip</span>
							</div>
						)}
					</div>
				)}

				{aiStatus === 'Thinking...' && (
					<>
						<div className={el('horizontal-half', 'upper')}>
							<span className={el('action-label')}>AI is thinking</span>
						</div>

						<div className={el('horizontal-half', 'lower-processing')}>
							<AnimatedBlob />
						</div>
					</>
				)}

				{/* {aiStatus === 'Listening...' && (
				<>
					<div className={el('horizontal-half', 'upper')}>
						<span className={el('action-label')}>Finish to talk to send your response</span>
					</div>

					<div className={el('horizontal-half', 'lower-frequency')}>
						<div
							className={el('frequency-container')}
							id="spectrogram"
							style={{ opacity: aiStatus === 'Listening...' ? 1 : 0 }}
						>
							{renderBarBins()}
						</div>
					</div>
				</>
			)} */}

				{aiStatus !== 'Thinking...' && (
					<div ref={textToShowRef} key="ai-latest-message" className={el('transcript')} />
				)}
				{/* <img
					onMouseDown={pttHold}
					onMouseUp={pttRelease}
					title="Press and hold this button to speak and release to complete."
					className={el('ptt-button')}
					ref={pttButtonRef}
					src={MicIcon}
				/> */}
				{/* TO DEBUG */}
				{/* {aiStatus === 'Listening...' && <span className={el('listening-span')}>Listening...</span>} */}
				<div
					className={el('image-overlay')}
					style={{
						display: aiStatus !== 'Thinking...' && demoImageURL ? 'flex' : 'none',
						opacity: showImage ? 1 : 0,
						cursor: showImage ? 'pointer' : 'default',
					}}
					onClick={onImageClick}
				>
					<img src={demoImageURL} alt="demo" ref={imgRef} />

					<div className={el('hover-overlay')}>
						<img src={ZoomIcon} alt="zoom" />
					</div>

					<span className={el('image-overlay-text')}>Click on the image to make it bigger</span>
				</div>

				<div ref={modalRef} className={el('modal-image')}>
					<div className={el('modal-insider')}>
						<img className={el('modal-image-content')} alt="" ref={imgModalRef} />

						<img
							className={el('modal-image-close')}
							src={xIcon}
							alt="close"
							onClick={onModalClick}
							aria-hidden
						/>
					</div>
				</div>
			</div>
		);
	},
);

Transcript.displayName = 'Transcript';

Transcript.propTypes = {
	aiStatus: PropTypes.string,
	showImage: PropTypes.bool,
	demoImageURL: PropTypes.string,
	squareWrapperSideSize: PropTypes.number,
};

export default Transcript;
