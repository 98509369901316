// packages
import { useCallback, useLayoutEffect, useState } from 'react';
import _ from 'lodash';

const useElementRect = () => {
	// state
	const [rect, setRect] = useState(new DOMRect(0, 0, 0, 0));
	const [element, setElement] = useState(null);

	// functions
	const setRef = useCallback((node) => {
		setElement(node);
	}, []);

	const handleResize = useCallback(() => {
		if (!element) {
			setRect(new DOMRect(0, 0, window.innerWidth, window.innerHeight));
			return;
		}
		setRect(element.getBoundingClientRect());
	}, [element]);

	// life cycle
	useLayoutEffect(() => {
		handleResize();

		const debouncedHandleResize = _.debounce(handleResize, 100);

		let resizeObserver = null;

		if (typeof ResizeObserver === 'function') {
			resizeObserver = new ResizeObserver(debouncedHandleResize);

			if (element) {
				resizeObserver.observe(element);
			}
		} else {
			// Fallback for older browsers
			window.addEventListener('resize', debouncedHandleResize);
			window.addEventListener('orientationchange', debouncedHandleResize);
		}

		return () => {
			if (resizeObserver) {
				resizeObserver.disconnect();
			} else {
				// Cleanup listeners for older browsers
				window.removeEventListener('resize', handleResize);
				window.removeEventListener('orientationchange', handleResize);
			}
		};
	}, [handleResize, element]);

	return [rect, setRef, element];
};

export default useElementRect;
