import React from 'react';
import AiIllustration from 'public/images/ai-illustration.png';
import Sparks from 'public/images/sparks.svg';

import cn from 'src/utilities/bem-cn';
import './styles.scss';

const className = 'ai-banner';

const el = (name) => cn(className, name);

export default function AiBanner() {
	return (
		<div className={el('ai-question-container')}>
			<div className={el('ai-container')}>
				<div className={el('ai-content')}>
					<span className={el('ai-listening')}>
						{' '}
						<img src={Sparks} alt="Sparks" />
						AI is listening ...
					</span>
					<span className={el('tell-us')}>Please tell us what you think about this idea ...</span>
				</div>
				<div className={el('ai-content')} style={{ justifyContent: 'center' }}>
					<img src={AiIllustration} alt="AiIllustration" className={el('smily-lady')} />
				</div>
			</div>
		</div>
	);
}
