// packages
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';

// actions
import * as actions from 'src/domains/main/actions';
// hooks
import useMediaPermissions from 'src/utilities/hooks/useMediaPermissions';
import useIsMobile from 'src/utilities/hooks/useIsMobile';
// selectors
import * as selectors from '../../../selectors';

// components
import VideoInteractor from './components/VideoInteractor';
import ModalPermissionSettings from './components/VideoPermissionChecker/ModalPermissionSettings';

const VideoGuidedQuestion = ({ question, productId, onSkip, onSuccess }) => {
	// state
	const [canSkip, setCanSkip] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const isMobile = useIsMobile();

	const {
		permissions: { isVideoPermissionGranted, isAudioPermissionGranted },
		refreshMediaPermissions,
		requestMediaPermissions,
		checkPermission,
	} = useMediaPermissions();

	const { responseId } = useSelector((state) => state.main);
	const { optionalVideoQuestions } = useSelector((state) => selectors.getMainState(state));
	const hasSelectedAudioOnly = useSelector((state) => state?.main?.audioOnly);

	const dispatch = useDispatch();

	// functions
	const setIsGuidedVideoQuestionInProgress = useCallback(
		(value) => dispatch(actions.setIsGuidedVideoQuestionInProgress(value)),
		[dispatch],
	);

	// life cycle
	useEffect(() => {
		const optQuestion = optionalVideoQuestions.find((q) => q.id === question.id);
		if (optQuestion) {
			setCanSkip(!optQuestion?.forceWebcam);
		}
	}, []);

	useEffect(() => {
		setIsGuidedVideoQuestionInProgress(true);

		return () => {
			setIsGuidedVideoQuestionInProgress(false);
		};
	}, [setIsGuidedVideoQuestionInProgress]);

	const onRequestPermission = async () => {
		if (hasSelectedAudioOnly) {
			await requestMediaPermissions({ audio: true });
			const mic = await checkPermission('microphone');
			if (!mic) {
				setShowModal(true);
			}
			return { isAudioPermissionGranted: mic, isVideoPermissionGranted: false };
		}

		await requestMediaPermissions({ audio: true, video: true });
		const camera = await checkPermission('camera');
		const microphone = await checkPermission('microphone');
		if (!camera && !microphone) {
			setShowModal(true);
		}
		return { isAudioPermissionGranted: microphone, isVideoPermissionGranted: camera };
	};

	return (
		<>
			<VideoInteractor
				{...{ responseId, question, onSuccess, productId, canSkip, onRequestPermission, onSkip }}
			/>

			{showModal && (
				<ModalPermissionSettings
					retry={() => {
						setShowModal(false);
						refreshMediaPermissions();
					}}
					onClose={() => {
						setShowModal(false);
					}}
					micPermission={!isAudioPermissionGranted}
					cameraPermission={!isVideoPermissionGranted}
					customStyle={{ top: isMobile ? '40px' : '140px' }}
				/>
			)}
		</>
	);
};

VideoGuidedQuestion.propTypes = {
	question: PropTypes.object.isRequired,
	productId: PropTypes.any,
	onSkip: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
};

export default VideoGuidedQuestion;
