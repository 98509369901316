import React from 'react';
import * as Sentry from '@sentry/browser';

export default class AppBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { error: null };
	}

	componentDidCatch(error, errorInfo) {
		if (process.env.logToSentry) {
			this.setState({ error });
			console.error('Sentry Error:', error);
			Sentry.withScope((scope) => {
				Object.keys(errorInfo).forEach((key) => {
					scope.setExtra(key, errorInfo[key]);
				});
				Sentry.captureException(error);
			});
		}
	}

	render() {
		if (process.env.logToSentry) {
			if (this.state.error) {
				// render fallback UI
				return <a onClick={() => Sentry.showReportDialog()}>Report feedback</a>;
			}
			// when there's not an error, render children untouched
			return this.props.children;
		}
		// when there's not an error, render children untouched
		return this.props.children;
	}
}
