// packages
import React from 'react';
import PropTypes from 'prop-types';

// utilities
import cn from 'src/utilities/bem-cn';

// styles
import './styles.scss';

const className = 'progress-bar';
const el = (name) => cn(className, name);

const ProgressBar = ({ activeItem, totalItemsCount, label, thumbnail, thumbnailLabel }) => (
	<div className={el('container')}>
		<div className={el('bar-info')}>
			{(!!thumbnail || !!thumbnailLabel) && (
				<div className={el('thumbnail-wrapper')}>
					{!!thumbnail && <img src={thumbnail} alt="" />}

					{!!thumbnailLabel && <p>{thumbnailLabel}</p>}
				</div>
			)}

			<span className={el('bar-percent')}>
				{label} {`${(activeItem + 1).toLocaleString()} / ${totalItemsCount.toLocaleString()}`}
			</span>
		</div>

		<div className={el('indicator')}>
			<div
				className={el('bar')}
				style={{
					width: `${(activeItem / totalItemsCount) * 100}%`,
					borderTopRightRadius: (activeItem / totalItemsCount) * 100 >= 97 ? '10px' : '0',
					borderBottomRightRadius: (activeItem / totalItemsCount) * 100 >= 97 ? '10px' : '0',
				}}
			/>
		</div>
	</div>
);

ProgressBar.propTypes = {
	activeItem: PropTypes.number,
	totalItemsCount: PropTypes.number,
	label: PropTypes.string,
	thumbnail: PropTypes.string,
	thumbnailLabel: PropTypes.string,
};

export default ProgressBar;
