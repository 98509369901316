// packages
import React from 'react';
import { PropTypes } from 'prop-types';
// utilities
import cn from 'src/utilities/bem-cn';
import './styles.scss';

import AiBanner from '../AiBanner';

const className = 'welcome-checker';

const el = (name) => cn(className, name);

const WelcomeChecker = ({ handleContinue, setAreYouSure }) => (
	<div className={className}>
		<div className={el('container')}>
			<div className={el('header')}>
				<span className={el('title')}>This survey contains video questions</span>
			</div>

			<div className={el('content')}>
				<span className={el('desc')}>
					If you choose to participate, you’ll be asked to record a video response using your camera and
					microphone.
				</span>
			</div>
			<div className={el('footer')}>
				<div aria-hidden onClick={handleContinue} className={el('button-primary')}>
					<span className={el('allow')}>Continue</span>
				</div>

				<div
					aria-hidden
					onClick={() => {
						setAreYouSure(true);
					}}
					className={el('button')}
				>
					<span className={el('reject')}>Skip video questions</span>
				</div>
			</div>
			<div className={el('disclaimer-container')}>
				<span className={el('disclaimer-text')}>
					By clicking ‘Continue,’ you consent to provide video responses and grant Upsiide and our clients a
					non-exclusive license to use, reproduce, and distribute your video for internal research and
					analysis. This consent is indefinite unless withdrawn in writing.
				</span>
			</div>
		</div>
		<div className={el('left-side-container')}>
			<AiBanner />
		</div>
	</div>
);

WelcomeChecker.propTypes = {
	handleContinue: PropTypes.func.isRequired,
	setAreYouSure: PropTypes.func.isRequired,
};

export default WelcomeChecker;
