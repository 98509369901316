import { datadogRum } from '@datadog/browser-rum';

let trackingEnv = 'local';
if (process.env.production) {
	trackingEnv = 'production';
} else if (process.env.staging) {
	trackingEnv = 'staging';
} else if (process.env.development) {
	trackingEnv = 'development';
} else if (process.env.protoOne) {
	trackingEnv = 'proto-one';
} else if (process.env.protoTwo) {
	trackingEnv = 'proto-two';
} else if (process.env.protoThree) {
	trackingEnv = 'proto-three';
} else if (process.env.protoFour) {
	trackingEnv = 'proto-four';
}

export const initDataDogRum = () => {
	try {
		console.log('Enabling RUM');
		datadogRum.init({
			applicationId: '5dc562f3-8004-4c2e-9b97-bd1f402de6e8',
			clientToken: 'pub9026cae98610355e5bbe2ec0aea3aa24',
			site: 'datadoghq.com',
			service: 'upsiide-survey',
			env: trackingEnv,
			// Specify a version number to identify the deployed version of your application in Datadog
			version: process.env.version,
			sampleRate: 100,
			trackInteractions: true,
			defaultPrivacyLevel: 'mask-user-input',
			// Connect APM traces to RUM
			allowedTracingOrigins: [/https:\/\/.*\.upsiide\.com/, /https:\/\/.*\upsi\.id/],
		});
		datadogRum.startSessionReplayRecording();
		console.log('RUM enabled');
	} catch (error) {
		console.log('error enabling RUM', error);
	}
};
